export const PAYMENT_STRIPE = "STRIPE";
export const PAYMENT_FLUTTERWAVE = "FLUTTERWAVE";
export const PAYMENT_PAYSTACK = "PAYSTACK";
export const PAYMENT_HUBTEL = "HUBTEL";
export const TX_STATUS_FAILED = "failed";
export const TX_STATUS_PENDING = "pending";
export const TX_STATUS_CONFIRMED = "confirmed";
export const TX_STATUS_REJECTED = "rejected";

export const ACCOUNT_TYPE_BUSINESS = "business";
export const ACCOUNT_TYPE_PERSONAL = "personal";
export const ACCOUNT_TYPE_VIEWER = "viewer";



///
export const STREAM_TYPE_WEBCAM = "webcam";
export const STREAM_TYPE_SCREEN = "screen_recording";
export const STREAM_TYPE_FILE = "file";
export const STREAM_STATUS_INACTIVE = "0";
export const STREAM_STATUS_ACTIVE = "1";
export const STREAM_STATUS_DONE = "2";

export const  EVT_STREAM_ACTIVE  = "stream_active";
export const  EVT_STREAM_DONE  = "done_publish";


export const COUPON_TYPE_PERCENTAGE = "percentage";
export const COUPON_TYPE_ABSOLUTE = "absolute";
export const EVT_RECORD_VIEW = "on_record_view";


export const  PAY_TYPE_PPV = 'ppv';
export const  PC_CONSTATE_STABLE = 'stable';

export const STATUS_TEXTS = {
    0:"not started",
    1:"ongoing",
    2:"ended",
}
export const STATUS_CLASS = {
    0:"info",
    1:"danger",
    2:"warning",
}

export const EVT_LIVESTREAM_STARTED = "livestream_started"

export const EVT_LIVESTREAM_ENDED = "livestream_ended";
export const EVT_LIVESTREAM_INTERUPTED = "livestream_interupted";
export const EVT_START_LIVESTREAM = "start_livestream";
export const EVT_STOP_LIVESTREAM = "stop_livestream";

export const EVT_RECORDING_STARTED = "recording_started";
export const EVT_RECORDING_ENDED = "recording_ended";
export const EVT_START_RECORDING = "start_recording";
export const EVT_STOP_RECORDING= "stop_recording";

export const EVT_RELAY_FAILED= "relay_failed";
export const EVT_MSG_SEND= "message.send";
export const EVT_MSG_NEW= "message.new";


export const EVT_LIVECHAT_INCOMING= "chat.incoming";


export const STREAM_SOURCE_ENCODER='encoder'
export const STREAM_SOURCE_STUDIO='studio'
export const STREAM_SOURCE_FILE='file'
export const STREAM_SOURCE_URL='url'


export const BANNER_TYPE_BREAKINGNEWS = 'breaking_news';
export const BANNER_TYPE_BASE = 'base';
export const BANNER_TYPE_CLASSIC = 'classic';
export const BANNER_TYPE_IMAGE = 'image';



export const STREAM_FILE_STATUS_PROCESSING = "1";
export const STREAM_FILE_STATUS_UNAVAILABLE = "0";
export const STREAM_FILE_STATUS_READY = "2";
export const STREAM_FILE_STATUS_FAILED = "3";
export const DEFAULT_IMAGE = "default.png";