import React ,{useContext,useState}from 'react'
import { Button } from 'antd'
import GlobalStateContext from '../../context'
import { Subscribe } from './Subscribe';

export default function UpgradeButton() {
    const [state,dipatch] = useContext(GlobalStateContext);
    const {user,active_package} = state
    const [showSubscriptionModal,setShowSubscriptionModal] = useState(false)


    return (
        <>
      
        <Subscribe/>
        
        </>
    )
}
