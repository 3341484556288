import React from 'react'

export default function Greetings({ user, title, description }) {
  return (
    <div className='greetings text-left'>
      <h5 className='text-gray-500 text-xl'> {title ? title : `Hi 👋, ${user?.name || ""}`}</h5>
      <div className=' text-gray-400 text-base md:text-lg font-light'> {description ? description : "We are happy to see you."}</div>
    </div>
  )
}
