
import React,{useState,useContext,useEffect} from 'react'
import { useHistory } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { getBrowserInnerDimensions, getRoute } from '../utils';

import GlobalStateContext from '../context';

import {BsCalendarEvent,BsPlayCircle} from "react-icons/bs"
import {MdOutlineCallSplit} from "react-icons/md"
import {MdOutlineAccountCircle} from "react-icons/md"
import {GoHome} from "react-icons/go"


export default function MobileMenu() {
  const iconBaseSize = 20;
    const [state,dispatch] = useContext(GlobalStateContext);
    const [bDimension, setDimentions] = useState(getBrowserInnerDimensions())
    const history = useHistory();
   const [activeMenu, setactiveMenu] = useState("mystudio")
    
   
   useEffect(() => {

    const updateDimentsions=()=>{
      setDimentions(getBrowserInnerDimensions());
    }
    
     console.log(getBrowserInnerDimensions())
      // Event listener for window resize
     window.addEventListener('resize', updateDimentsions);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateDimentsions);
    };
   }, [])
   
   
   return <BottomNavigation
    style={{position:"fixed",left:"0",right:"0",bottom:"0",top:`${bDimension.height -56}px`}}
    value={activeMenu}
    
    onChange={(event, newValue) => {
        setactiveMenu(newValue);
        history.push(getRoute(newValue)?.path)
    }}
    showLabels

  >
    {/* <BottomNavigationAction label="Play"  value="watch" icon={<BsPlayCircle  size={iconBaseSize}/>}  /> */}
    <BottomNavigationAction label="Destinations" value="destinations" icon={<MdOutlineCallSplit size={iconBaseSize} />} />
    <BottomNavigationAction label="Home" value="dashboard" icon={<GoHome size={iconBaseSize} />} />
    <BottomNavigationAction label="Events" value="myevents" icon={<BsCalendarEvent size={iconBaseSize}/>}  />
    {/* <BottomNavigationAction label="Account" value="account" icon={<MdOutlineAccountCircle size={iconBaseSize} />}  /> */}

    
  </BottomNavigation>
}

    