import React from 'react'
import { Avatar, Card, Skeleton } from 'antd'
import Meta from 'antd/lib/card/Meta';




export default function LoadingCards({ number = 5, alignment = 'column', avatar = false, dark = false }) {
    const cards = [];
    for (let index = 0; index < number; index++) {
        cards[index] = <div className={`rounded-lg p-2 animate-puls ${dark ? 'dark' : ''}`}>
            <div className="h-32 dark:bg-gray-600 bg-gray-200 rounded-lg mb-2 flex flex-row p-2">

                
                {/* <div className='w-16 h-16 rounded-full dark:bg-gray-400 bg-gray-100 my-auto '></div> */}
              
                <div className="flex flex-col justify-center w-full h-full pl-2 gap-1">
                    <div className="h-4 w-3/4 dark:bg-gray-600 bg-gray-100 rounded"></div>
                    <div className="h-4 w-3/5 dark:bg-gray-600 bg-gray-100 rounded"></div>
                    <div className="h-4 w-3/4 dark:bg-gray-600 bg-gray-100 rounded"></div>
                </div>
            </div>
        </div>
    }

    return (
        <div className={`grid grid-cols-2 sm:grid-cols-3 md md:grid-cols-4 lg:grid-cols-6  mt-6 gap-x-5 gap-y-5`}>
            {
                cards
            }

        </div>
    )
}

