import React, { useContext } from 'react'
import GlobalStateContext from '../../context'
import { Link, withRouter } from 'react-router-dom';
import { Dropdown, Menu, Icon, Avatar, Divider } from 'antd';
import Subscribe from './Subscribe';
import { LOGOUT, SET_ITEM } from "../../store/actions/types";
import { protectedRoutesPrefix } from '../../routers/routes';
import { url, stringInitials, genrateAvatarImageLink, genrateInitialsImageLink } from '../../utils';
import logo from "../../assets/images/logo_transparent_cut_landscape.png";
import DataSummary from './DataSummary';
import liveIcon from '../../live.svg';
import { isMobile } from 'react-device-detect';


export default withRouter(function Header(props) {
    const [state, dispatch] = useContext(GlobalStateContext)
    const { user, hide_nav } = state;

    const queryParams = new URLSearchParams(props.location.search);

    const logout = () => {
        dispatch(
            {
                type: LOGOUT
            }
        )
    }

    const authMenuItems = (
        // <Menu>
        <ul className="list-group" >
            <Link className="list-group-item" to={url("account", true)}> Account </Link>
            <Link to={''} className="list-group-item" onClick={logout}> Logout </Link>
        </ul>


        // </Menu>
    )



        ;
    return (

        !queryParams.get("hideHeader") ?

            <div className="nav-stack" >
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container">

                        <Link className="navbar-brand" to={"/" + protectedRoutesPrefix}>
                     
                            <img hidden={hide_nav} alt="" className="img-fluid w-14 d-inline-block align-top" src={logo} onClick={_ => {
                                !isMobile && dispatch({
                                    type: SET_ITEM,
                                    payload: ["hide_nav", !hide_nav]
                                })
                            }} />
                        </Link>
                        {state.isLive ? <Avatar shape="square" src={liveIcon} /> : null}


                        <div className=" " id="navbarNav" style={{ flexDirection: "row-reverse" }}>
                            <ul className="navbar-nav  form-inline">
                                <li className="nav-item m-r-15" key="_1">
                                    <Subscribe />
                                </li>

                                <li className="nav-item m-r-15" key="_2">
                                    <Dropdown overlay={authMenuItems} className="text-info">
                                        <Link to="#">
                                        <object data={user.picture} type="image/jpeg" className="w-8 h-8 md:w-12 md:h-12 bg-gradient-to-br from-purple-500 to-pink-500 rounded-full flex items-center justify-center text-white text-2xl font-bold">
                                            <img src={genrateInitialsImageLink(user.name)} alt="Name" />
                                        </object>
                                           
                                        </Link>
                                    </Dropdown>
                                </li>

                            </ul>
                        </div>

                    </div>
                </nav>
                {/* <DataSummary type="bar"/> */}
            </div>
            :
            <></>

    )
})

