import { Avatar, Empty, Icon } from 'antd';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import StreamWebService from '../../classes/StreamWebService';
import GlobalStateContext from '../../context';
import env from '../../environments';
import CustomSearch from '../../pages/protected/streams/components/CustomSearch';
import { buildCategoryHashtags, genrateAvatarImageLink, url } from '../../utils';
import Greetings from '../Greetings';
import LoadingCards from '../LoadingCards';
import LocalMoneyValue from '../LocalMoneyValue';
import ReactPlayer from 'react-player/lazy';
import Player from '../Player';
import { Box, LinearProgress } from '@material-ui/core';



export function StreamItemBase(props) {

    const { item } = props

    const [state,] = useContext(GlobalStateContext);

    const isPremium = item.ppv_value > 0.00;
    const { user, stream_destinations } = state;

    const { destinations } = item;
    const _itemLink = (!!user || isPremium) ? url(`stream/${item.id}/watch`, true) : url(`${item.id}/watch`, false)



    const buildCategory = () => {
        const cat = item.category;
        return typeof cat === String ?
            <span className="badge badge-info">{cat || "N/A"}</span> :
            item.category.map(ct => <span key={ct} className="badge badge-info m-r-5">{ct}</span>)
    }
    const buildDestinations = () => {
        const already = []
        return destinations.map(dest => {
            if (already.indexOf(dest.type) == -1) {
                already.push(dest.type)
                const ddt = stream_destinations.find(_ => _.id == dest.type);
                return ddt ? <Avatar size="small" className="m-1" src={ddt.logo} /> : <></>
            }
        })
    }

    return (
        <div className="h-30 w-72 max-w-sm  overflow-hidden transition-all duration-200 transform bg-white border border-gray-200 rounded-2xl hover:shadow-lg hover:-translate-y-1">
            <Link to={_itemLink}>
                <div className="px-3 py-3 sm:p-2">
                    <div className="flex items-start lg:items-center">

                        <img className="lg:h-20 w-14 h-14 lg:w-20 rounded-xl bg-no-repeat bg-contain" src={item.image} alt="" />

                        <div className="flex-1 ml-4 lg:ml-6">
                            <p className="text-xs font-medium text-gray-900 lg:text-sm whitespace-nowrap overflow-ellipsis overflow-hidden">
                                <span className=" flex flex-row overflow-ellipsis overflow-hidden">  {item.title} {isPremium && "$$"} </span>
                                {/* <span>live</span> */}
                            </p>
                            <p className="text-sm text-gray-900 lg:text-lg group-hover:text-gray-600">
                                <span className="">  {buildCategoryHashtags(item)}</span>
                            </p>

                            <p className="text-xs font-medium text-gray-500 lg:text-sm">{item?.user?.name}</p>
                        </div>
                    </div>
                </div>
            </Link>
        </div >
    )
}
export function StreamItemBasePreview(props) {

    const { item } = props

    const [state,] = useContext(GlobalStateContext);
    const [play, setPlay] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const isPremium = item.ppv_value > 0.00;
    const { user, stream_destinations } = state;
    const playerRef = useRef()

    const { destinations } = item;
    // const _itemLink = (!!user || isPremium) ? url(`stream/${item.id}/watch`, true) : url(`${item.id}/watch`, false)
    const _itemLink = url(`${item.id}/watch`, false)

    const fallbackImageUrl = genrateAvatarImageLink(item?.user?.name)

    const buildCategory = () => {
        const cat = item.category;
        return typeof cat === String ?
            <span className="badge badge-info">{cat || "N/A"}</span> :
            item.category.map(ct => <span key={ct} className="badge badge-info m-r-5">{ct}</span>)
    }
    const buildDestinations = () => {
        const already = []
        return destinations.map(dest => {
            if (already.indexOf(dest.type) == -1) {
                already.push(dest.type)
                const ddt = stream_destinations.find(_ => _.id == dest.type);
                return ddt ? <Avatar size="small" className="m-1" src={ddt.logo} /> : <></>
            }
        })
    }


    const onMouseOver = evt => {
        if (!play) {
            console.log("Mouse Over")
            setIsLoading(true)
            setPlay(true);
        }
    }
    const onMouseLeave = evt => {

        if (!!play) {
            console.log("Mouse Leave")
            setIsLoading(false)
            setPlay(false);
        }
    }
    const onBuffering = evt => {

        console.log("media is loading")
        setIsLoading(true)


    }
    const onReady = evt => {

        console.log("media ready")
        setIsLoading(false)

    }
    return (
        <div key={item.id} className=" rounded-lg max-w-sm  overflow-hidden transition-all duration-200 transform  hover:shadow-lg hover:-translate-y-1 hover:border">
            <Link to={_itemLink} >
                <div className='flex flex-col'>
                    <div onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} className="center rounded-lg  aspect-w-16 aspect-h-9 bg-no-repeat bg-cover  bg-black" style={{ backgroundImage: `url(${item?.image})` }} alt="">
                        <span className='px-2' style={{ position: "absolute" }}>
                            {item.status === '0' &&
                                (
                                    <span className={`px-2 badge`} style={{ margin: "5px" }}>Upcoming</span>
                                )
                            }
                            {item.status === '1' &&
                                (
                                    <span className={`px-2 badge badge-danger`} style={{ margin: "5px" }}>LIVE</span>
                                )}

                        </span>
                        {isLoading && <div>
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box></div>}

                        {
                            item.status === '0' ?
                                <ReactPlayer className="   rounded-lg" onBuffer={onBuffering} onBufferEnd={onReady} muted playIcon={<></>} url={item.video} light={!item?.image} width={"100%"} height={"100%"} />
                                :
                                <ReactPlayer className="   rounded-lg" onBuffer={onBuffering} onBufferEnd={onReady} muted playIcon={<></>} url={item.video} playing={play} light={!play} width={"100%"} height={"100%"} />

                        }

                        {/* {item.video.indexOf("youtu") > -1 && <span className='px-1 bg-gray-600 relative bottom-6 text-gray-300 text-xs mx-2 mb-2'> external </span>} */}
                    </div>
                    <div className="px-1 py-1 ">
                        <div className="flex items-start lg:items-center">
                            <div className="flex-shrink-0  mx-2 lg:-mt-8">
                              
                                <object data={item?.user?.picture} type="image/jpeg"  className="w-8 h-8 rounded-full bg-gray-100">
                                    <img src={fallbackImageUrl} alt={item?.image} />
                                </object>
                            </div>
                            <div className="flex-1 ">
                                <p className="text-xs font-medium text-gray-300 lg:text-sm whitespace-nowrap overflow-ellipsis overflow-hidden">
                                    <span className=" flex flex-row overflow-ellipsis overflow-hidden">  {item.title} {isPremium && "$$"} </span>
                                </p>
                                {(item.status == 0 && item.start_at) && <p className="text-sm text-gray-400 lg:text-lg group-hover:text-gray-300">
                                    <span className="">{item.start_at} </span>
                                </p>}
                                <p className="text-sm text-gray-400 lg:text-lg group-hover:text-gray-300">
                                    <span className="">  {buildCategoryHashtags(item)}</span>
                                </p>
                                <p className="text-xs font-medium text-gray-500 lg:text-sm">{item?.user?.name}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </Link>
        </div >
    )
}


export function LiveEventItem({ item }) {
    const { streamingServer } = env.app.api;
    const { live } = streamingServer.apps
    const [state,] = useContext(GlobalStateContext);

    const [color, setColor] = useState(["green", "danger", "warning"][item.status]);
    const isPremium = item.ppv_value > 0.00;
    const { user, stream_destinations } = state;

    const snapshot = item.image;


    return (


        // <StreamItemBase item={item} />
        <StreamItemBasePreview item={item} />


    )
}

export default function LiveEventsHero({ onReady, user_id, title, description }) {
    const itemsPerPage = 15;
    const [overallCount, setOveralCount] = useState(0);
    var itemsSet = 1;
    const [state, setstate] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const webs = new StreamWebService();
    const [payload, setPayload] = useState({
        page: itemsSet, perPage: itemsPerPage,
        user: user_id
    });
    const getPublicEvents = () => {
        setIsLoading(true)

        webs.getOpenPublicStreams(payload)
            .then(res => {
                setOveralCount(res.data.overallCount)
                return res.data.entries
            })
            .then(items => {

                setstate([...state, ...items]);
                onReady && onReady(items);
            })

            .finally(_ => setIsLoading(false));
    }

    const loadMore = () => {
        setPayload({ ...payload, page: payload.page + 1 })

    }

    const cleanup = () => setstate([]);
    useEffect(() => {
        getPublicEvents();
    }, [payload])
    useEffect(() => {
        getPublicEvents()
        return cleanup
    }, [])


    return (
        <div className="relative flex items-end bg-gray-900 sm:px-6 lg:pb-24  col-span-2 hidden lg:block overflow-y-scroll ">
            <div className="absolute inset-0">
                <img className="object-cover w-full h-full" src="https://landingfoliocom.imgix.net/store/collection/clarity-blog/images/hero/6/grid-pattern.svg" alt="" />
            </div>
            <div className="relative  w-full max-w-lg  mx-auto lg:max-w-none overflow-y-scroll" style={{ maxHeight: "50.5rem" }}>
                <p className="text-3xl font-bold text-white text-center mt-6 ">
                    {/* Broadcasts */}
                </p>

                <div className="flex flex-row flex-wrap mt-6  justify-center ">

                    {isLoading
                        ?
                        <LoadingCards dark={true} />
                        :
                        state.length > 0
                            ?
                            state.map(item => <div className='my-2  mx-2'> <LiveEventItem item={item} key={item.id} /> </div>)
                            :
                            <Empty />
                    }

                </div>
            </div>
        </div>

    )
}
