import React, { useState, useContext, useEffect } from 'react'
import { Layout, Menu } from 'antd';
import { buildMenuItems } from "../../utils";
import { protectedRoutes, protectedRoutesPrefix } from "../../routers/routes";
import GlobalStateContext from '../../context';
import logo from "../../assets/images/logo_transparent_cut_landscape.png";
import { Link, useLocation } from 'react-router-dom';
import Subscribe, { SubscribeButton } from './Subscribe';
const { Sider } = Layout;

export default function Sidebar({hidden}) {

  const [gState, dispatch] = useContext(GlobalStateContext)
  const { configuration } = gState;
  const [state, setState] = useState({
    collapsed: false,
  });
  const location =  useLocation();
  

  const onCollapse = collapsed => {
    setState({ collapsed });

    dispatch({
      type: 'SET_ITEM',
      payload: ['collapsed_nav',collapsed]
    })
    // dispatch({
    //   type: 'SET_ITEM',
    //   payload: ['hide_nav',true]
    // })
  };

  const style={
    overflow: 'auto',
    height: '100vh',
    position: 'fixed',
    left: 0,
  }


  useEffect(()=>{
    onCollapse()
  },[])


  return (
    <Sider theme='light' reverseArrow hidden={hidden} collapsible collapsed={state.collapsed} onCollapse={onCollapse} style={style} title="sdasd" >
        <div className=' m-t-20'>

        <Link className="logo" to={`/${protectedRoutesPrefix}`}>
          <img alt="" className="img-fluid  d-inline-block align-top" src={logo} />
        </Link>
        </div>
      <Menu className="" theme="drk" defaultSelectedKeys={['1']} mode="inline" style={{marginTop:"30px"}} selectedKeys={[location.pathname]}>
        {buildMenuItems(protectedRoutes)}
      </Menu>
      <SubscribeButton/>
    </Sider>
  )
}
