import React,{useState} from 'react'
import UpgradeButton from './protected/UpgradeButton'
import { Modal } from 'antd'
import { SubscribeButton } from './protected/Subscribe'

export default function SubscribePopup({visible,onClose}) {
    const [showInstantModal, setshowInstantModal] = useState(visible || true)
    return (
        <>
            <Modal
           
                footer={false}
                closable={false}
                onCancel={_ =>{ onClose && onClose();setshowInstantModal(false)}} visible={showInstantModal}>
                <div className='mx-auto'>
                    <div className='m-2'>
                        <h2 className='text-lg'>Premium Feature</h2>
                        <p>Your current plan does not allow access to this feature or you may have exceeded your allowed limit.</p>
                    <SubscribeButton/>
                    </div>

                </div>
            </Modal>
        </>


    )
}
